import { lazy } from "react";

const Home = lazy(() => import("./Components/Home/Home"));
const Blog = lazy(() => import("./Components/Blog/Blog"));
const BlogDetail = lazy(() => import("./Components/Blog/BlogDetail"));
const Project = lazy(() => import("./Components/Project/Project"));
const Contact = lazy(() => import("./Components/Contact/Contact"));
const About = lazy(() => import("./Components/About/About"));
const Game = lazy(() => import("./Components/game/game.component"));
const Privacy = lazy(() => import("./Components/Privacy/Privacy"));
const Login = lazy(() => import("./Components/Login/Login"));
const PlatForm = lazy(() => import("./Components/platform/platform.component"));
const NewsLetter = lazy(() => import("./Components/news/news.component"));

const routes = [
  { path: "/", component: <Home /> },
  { path: "blog", component: <Blog /> },
  { path: "blog/:blogId", component: <BlogDetail /> },
  { path: "game", component: <Game /> },
  { path: "project", component: <Project /> },
  { path: "contact", component: <Contact /> },
  { path: "about", component: <About /> },
  { path: "Privacy", component: <Privacy /> },
  { path: "news", component: <NewsLetter /> },
  { path: "platform", component: <PlatForm /> },
  { path: "login", component: <Login /> },
];

export default routes;
