import React, { useState } from "react";
// import Container from "react-bootstrap/Container";
// import Nav from "react-bootstrap/Nav";
// import Navbar from "react-bootstrap/Navbar";
import { Navbar, NavDropdown, Nav, Container } from "react-bootstrap";
import { useLocation, Link } from "react-router-dom";

import model_image from "../../assets/images/model-image.jpg";
import header_btn from "../../assets/icons/nav-btn.png";
import header_btn_oo from "../../assets/icons/nav-btn-oo.png";
import Modal from "react-bootstrap/Modal";
let { Body } = Modal;
let { Toggle, Collapse } = Navbar;
let { Item } = NavDropdown;

const Header = () => {
  const location = useLocation();
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  window.onscroll = function () {
    scrollFunction();
  };
  function scrollFunction() {
    if (
      document.body.scrollTop > 50 ||
      document.documentElement.scrollTop > 50
    ) {
      document.getElementById("header").classList.add("scroll-sticky");
    } else {
      document.getElementById("header").classList.remove("scroll-sticky");
    }
  }

  const openInNewTab = (url) => {
    window.open(url, "_blank", "noopener,noreferrer");
  };

  return (
    <>
      <Navbar className="top_navbar" sticky="top" id="header" expand="lg">
        <Container className="px-3">
          <Link to="/">
            <Navbar.Brand>
              {" "}
              <img src="/assets/images/svg/logo.svg" alt="vorbit logo" />{" "}
            </Navbar.Brand>
          </Link>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse
            className="justify-content-end"
            id="basic-navbar-nav"
          >
            <Nav className="me-auto navbar_links">
              <Link
                to="/"
                className={`nav-link ${
                  location.pathname === "/" ? "active" : ""
                }`}
              >
                Home
              </Link>
              <Link
                to="/about"
                className={`nav-link ${
                  location.pathname === "/about" ? "active" : ""
                }`}
              >
                About us
              </Link>
              <Link
                to="/contact"
                className={`nav-link ${
                  location.pathname === "/contact" ? "active" : ""
                }`}
              >
                Contact Us
              </Link>

              <Link
                to="/blog"
                className={`nav-link ${
                  location.pathname === "/blog" ? "active" : ""
                }`}
              >
                Blog
              </Link>
              {/* <Link  className="nav-link" to="http://google.com" target="_blank" rel="noreferrer">Marketplace</Link> */}
              {/* <Link to="/contact" className="nav-link">Builder</Link> */}
              {/* <Link to="#" onClick={handleShow} className="nav-link">Marketplace</Link> */}
              {/* <a
                className="nav-link"
                target="_blank"
                rel="noreferrer"
                href="https://nft.vorbit.org"
              >
                Marketplace
              </a> */}
            </Nav>
            <NavDropdown className="nav-link partners-links" title="Partners">
              <Item onClick={() => openInNewTab("https://algocoder.org")}>
                AlgoCoder
              </Item>
              <Item onClick={() => openInNewTab("https://nft.vorbit.org")}>
                NFT Market Place
              </Item>
              <Item disabled>E-commerce</Item>
            </NavDropdown>
          </Navbar.Collapse>
          {/* <Link className="btn btn_started" onClick={handleShow} to="#">
            Get Start
          </Link> */}
          <Link className="btn vorbit-start-btn" to="/game">
            <div className="btn-oo">
              <img src={header_btn_oo} alt="headeroo" />
              <span>Get Start</span>
            </div>
            <img className="w-100 btn-img" src={header_btn} alt="headerbtn" />
          </Link>
        </Container>
      </Navbar>

      <Modal
        className="custom-model-header"
        style={{ background: "#0d0c0cab" }}
        show={show}
        onHide={handleClose}
      >
        <Body className="custom-model-body" style={{ position: "relative" }}>
          <div className="model-image-header">
            <img src={model_image} />
            <h1 className="model-body-header">comming soon</h1>
          </div>
        </Body>
      </Modal>
    </>
  );
};

export default Header;
