import "bootstrap/dist/css/bootstrap.min.css";

import { lazy, Suspense } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Header from "./Components/Layout/Header";
import Footer from "./Components/Layout/Footer";
import routes from "./routes";

import { getCookie } from "./utils/cookie";
import "./assets/styles/global.scss";
import Game from "./Components/game/game.component";

const Login = lazy(() => import("./Components/Login/Login"));

function App() {
  return (
    <>
      <BrowserRouter>
        <Suspense fallback="">
          <Header />
          <Routes>
            {routes.map((data, index) => (
              <>
                {data.path === "game" ? (
                  <Route
                    onUpdate={() => window.scrollTo(0, 0)}
                    exact={true}
                    path={data.path}
                    element={getCookie("login") ? data.component : <Login />}
                    key={index}
                  />
                ) : (
                  <Route
                    onUpdate={() => window.scrollTo(0, 0)}
                    exact={true}
                    path={data.path}
                    element={data.component}
                    key={index}
                  />
                )}
              </>
            ))}
            {/* {routes.map((data, index) => (
            
            ))} */}
          </Routes>
          <Footer />
        </Suspense>
      </BrowserRouter>
    </>
  );
}

export default App;
