// import React, { useState, useEffect } from "react";
// // import Unity, { UnityContext } from "react-unity-webgl";
// import Unity, { UnityContext } from "react-unity-webgl";

// // // import GameNav from '../Share/NavBar/gameNav'
// // // import '../Share/NavBar/navbar'

// // const unityContext

// // unityProvider, loadingProgression, isLoaded
// ////////////// Atari Game
// // loaderUrl: "build/WebGLVRAlphavarseMultiplayer.loader.js",
// // dataUrl: "build/WebGLVRAlphavarseMultiplayer.data.unityweb",
// // frameworkUrl: "build/WebGLVRAlphavarseMultiplayer.framework.js.unityweb",
// // codeUrl: "build/WebGLVRAlphavarseMultiplayer.wasm.unityweb",

// ////////////// Musa wla build
// function Game() {
//   // console.log("unity context ****** ", unityProvider);
//   const { unityProvider, isLoaded, loadingProgression } = UnityContext({
//     loaderUrl: "Build/Vrobit Metavarse_20221011_103110_wasm_release.loader.js",
//     dataUrl: "Build/Vrobit Metavarse_20221011_103110_wasm_release.data",
//     frameworkUrl:
//       "Build/Vrobit Metavarse_20221011_103110_wasm_release.framework.js",
//     codeUrl: "Build/Vrobit Metavarse_20221011_103110_wasm_release.wasm",
//     productName: "Vorbit",
//     productVersion: "1.0.0",
//     companyName: "Vorbit",
//   });

//   const [isGameOver, setIsGameOver] = useState(false);
//   const [userName, setUserName] = useState("");
//   const [score, setScore] = useState(0);

//   // useEffect(function () {
//   //   unityContext.on("GameOver", function (userName, score) {
//   //     setIsGameOver(true);
//   //     setUserName(userName);
//   //     setScore(score);
//   //   });
//   // }, []);

//   return (
//     <div className="game-container">
//       <a href="/" className="btn btnHome">
//         Home
//       </a>
//       {/* <GameNav /> */}
//       {/* {isGameOver === true && <p>{`Game Over! ${userName} ${score} points`}</p>} */}
//       <Unity
//         // unityContext={unityContext}
//         unityProvider={unityProvider}
//         style={{
//           height: "100vh",
//           width: "99.8vw",
//           border: "2px solid black",
//           background: "grey",
//         }}
//       />
//     </div>
//   );
// }

// export default Game;

// // import React from "react";
// // import { Unity, useUnityContext } from "react-unity-webgl";
// // function App() {
// //   const { unityProvider, loadingProgression, isLoaded } = useUnityContext({
// //     loaderUrl: "build/myunityapp.loader.js",
// //     dataUrl: "build/myunityapp.data",
// //     frameworkUrl: "build/myunityapp.framework.js",
// //     codeUrl: "build/myunityapp.wasm",
// //   });
// //   return (
// //     <>
// //       {!isLoaded && (
// //         <p>Loading Application... {Math.round(loadingProgression * 100)}%</p>
// //       )}
// //       <Unity
// //         unityProvider={unityProvider}
// //         style={{ visibility: isLoaded ? "visible" : "hidden" }}
// //       />
// //     </>
// //   );
// // }

import React from "react";
import { Unity, useUnityContext } from "react-unity-webgl";
import ustad_altamash_gif from "../../assets/images/altamash-ustad.gif";

function Game() {
  const { unityProvider, isLoaded } = useUnityContext({
    loaderUrl: "Build/Vrobit Metavarse_20221012_071005_wasm_release.loader.js",
    dataUrl: "Build/Vrobit Metavarse_20221012_071005_wasm_release.data",
    frameworkUrl:
      "Build/Vrobit Metavarse_20221012_071005_wasm_release.framework.js",
    codeUrl: "Build/Vrobit Metavarse_20221012_071005_wasm_release.wasm",
    productName: "Vorbit",
    productVersion: "1.0.0",
    companyName: "Vorbit",
  });

  return (
    <div className="game-container">
      <a href="/" className="btn btnHome">
        Home
      </a>
      {isLoaded === false && (
        <div className="loading-overlay">
          <img src={ustad_altamash_gif} alt="imgesname" className="w-100" />
        </div>
      )}

      <Unity
        className="unity"
        unityProvider={unityProvider}
        style={{
          height: "100vh",
          width: "99.8vw",
          border: "2px solid black",
          background: "grey",
        }}
      />
    </div>
  );
}
export default Game;
